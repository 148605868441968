import { Portfolio } from '@netpurpose/api'
import { ColumnsType, getFilterColumnConfig, PortfolioBadge } from '@netpurpose/np-ui'
import { FilterConfig, PaginationConfig } from '@netpurpose/types'
import { formatDollars, formatRatioAsPercent } from '@netpurpose/utils'
import { ColumnTitleWithInfoTooltip } from '#components/common/ColumnTitleWithInfoTooltip'
import { Monospaced } from '#components/common/dataRendering'
import { getRenderNumericData } from '#services/dataRendering'
import { PortfolioForDisplay } from './formatRowData'

const portfolioTypeMap: Record<string, string> = {
  private: 'Portfolio',
  benchmark: 'Benchmark',
  watchlist: 'Watchlist',
}

// TODO: drop this when portfolio comparison is also transitioned to using
// new PortfolioSummary component.
export const getColumns = (
  paginationConfig?: PaginationConfig,
  filterConfig?: FilterConfig<Portfolio>,
  logTooltipHoverEvent: (tooltipName: string) => void = () => {},
): ColumnsType<PortfolioForDisplay> => [
  {
    title: 'Name',
    dataIndex: 'name',
    ...(filterConfig &&
      paginationConfig &&
      getFilterColumnConfig('alpha', 'name', filterConfig, paginationConfig)),
  },
  {
    title: 'Type',
    dataIndex: 'type',
    render: (type) => <PortfolioBadge type={type} />,
    ...(filterConfig &&
      paginationConfig &&
      getFilterColumnConfig('enum', 'type', filterConfig, paginationConfig, {
        frontEndEnum: Object.entries(portfolioTypeMap).map(([key, val]) => ({
          label: val,
          value: key,
        })),
      })),
  },
  {
    title: 'AUM ($)',
    dataIndex: 'value',
    render: getRenderNumericData<PortfolioForDisplay['value']>(formatDollars),
    align: 'right',
    ...(filterConfig &&
      paginationConfig &&
      getFilterColumnConfig('number', 'value', filterConfig, paginationConfig)),
  },
  {
    title: '# Holdings',
    dataIndex: 'numHoldings',
    render: getRenderNumericData<PortfolioForDisplay['numHoldings']>(),
    align: 'right',
    ...(filterConfig &&
      paginationConfig &&
      getFilterColumnConfig('number', 'numHoldings', filterConfig, paginationConfig)),
  },
  {
    title: () => (
      <ColumnTitleWithInfoTooltip
        title="Coverage %"
        text="Proportion by value"
        logTooltipHoverEvent={logTooltipHoverEvent}
      />
    ),
    dataIndex: 'valueCovered',
    render: getRenderNumericData<PortfolioForDisplay['valueCovered']>(formatRatioAsPercent),
    align: 'right',
    ...(filterConfig &&
      paginationConfig &&
      getFilterColumnConfig('number', 'valueCovered', filterConfig, paginationConfig, {
        precision: 2,
        type: 'percentage',
      })),
  },
  {
    title: 'Coverage #',
    dataIndex: 'numHoldingsCovered',
    render: (holdingsCovered: PortfolioForDisplay['numHoldingsCovered'], record) => (
      <Monospaced>
        {holdingsCovered}/{record.numHoldings}
      </Monospaced>
    ),
    align: 'right',
  },
  {
    title: 'Valuation Date',
    dataIndex: 'valuationDate',
    render: (value) => <Monospaced>{value}</Monospaced>,
    align: 'right',
    ...(filterConfig &&
      paginationConfig &&
      getFilterColumnConfig('date', 'valuationDate', filterConfig, paginationConfig)),
  },
]
