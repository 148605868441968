import { useState } from 'react'
import { PortfolioType, useUploadPortfolio } from '@netpurpose/api'
import { useModalContext } from '@netpurpose/core'
import { Button, Icon, LinkText, Modal, SwitchButton } from '@netpurpose/np-ui'
import { useTasks } from '#context/tasks'
import {
  BottomContainer,
  DragUpload,
  IconTextContainer,
  LabelText,
  ModalBodyContainer,
  ModalHeaderContainer,
  ModalText,
  Separator,
  StepCounter,
  TextList,
  UploadContainer,
  UploadedFileContainer,
} from './CreatePortfolioAction.style'
import { CreatePortfolioForm } from './CreatePortfolioForm'

const DirectText = () => (
  <>
    <Modal.HeaderText>Direct holdings portfolio</Modal.HeaderText>
    <div>
      <ModalText>
        To create a portfolio you will need to provide a .xlsx file with a list of holdings, with
        the holding weights.
      </ModalText>
      <TextList>
        <li style={{ width: 'fit-content' }}>
          <a
            href="/downloads/direct-holdings-upload-template.xlsx"
            download="Net Purpose - Create your direct holdings portfolio template.xlsx"
          >
            <LinkText>
              <IconTextContainer>
                Download the 'Portfolio upload' template
                <Icon icon="Download" alt="Download icon" inheritColor />
              </IconTextContainer>
            </LinkText>
          </a>
        </li>
        <li>Go to the 'Portfolio template' sheet. </li>
        <li>
          Fill in the template with holding names, ISINs and weights. Use one row per holding. The
          total portfolio weights may be below but not exceed 100%. Cash holdings may be removed or
          included by using CCY CASH as the ISIN.
        </li>
        <li>Save the excel as .xlsx.</li>
        <li>Upload the .xlsx below.</li>
      </TextList>
    </div>
  </>
)

const FundsText = () => (
  <>
    <Modal.HeaderText>Fund of funds portfolio</Modal.HeaderText>
    <div>
      <TextList>
        <li>
          <a
            href="/downloads/fund-of-funds-upload-template.xlsx"
            download="Net Purpose - Create your fund of funds portfolio template.xlsx"
          >
            <LinkText>
              <IconTextContainer>
                Download the 'Fund of funds portfolio upload' template
                <Icon icon="Download" alt="Download icon" inheritColor />
              </IconTextContainer>
            </LinkText>
          </a>
        </li>
        <li>Go to the 'Fund of funds template' sheet. </li>
        <li>
          Fill in the template with fund names, ISINs and weights. You may use an ISIN or a unique
          code for the ISIN field for funds. This is your fund of funds portfolio. The total weight
          of your portfolio may be below 100% but not above. For cash holdings, you may remove or
          include using CCY CASH as the ISIN.
        </li>
        <li>
          For your first fund, use the 'Fund holdings template' sheet and rename the sheet with the
          fund ISIN field used in Step 2. Fill in the template with security names, ISINs and
          weights. These are the holdings within your fund.
        </li>
        <li>
          Duplicate the 'Fund holdings template' sheet and repeat step 4 for all the funds in your
          fund of funds portfolio.
        </li>
        <li>Save the excel as .xlsx.</li>
        <li>Upload the .xlsx below.</li>
      </TextList>
    </div>
  </>
)

const UploadHoldings = ({
  step,
  setStep,
  portfolioFile,
  setPortfolioFile,
  setErrorMessage,
  isPending,
  uploadType,
  setUploadType,
}: {
  step: 1 | 2
  setStep: (step: 1 | 2) => void
  portfolioFile: File | undefined
  setPortfolioFile: (file: File | undefined) => void
  setErrorMessage: (message: string | null) => void
  isPending: boolean
  uploadType: 'direct' | 'funds'
  setUploadType: (uploadType: 'direct' | 'funds') => void
}) => (
  <ModalBodyContainer $visible={step === 1}>
    <SwitchButton
      options={[
        {
          label: 'Direct holdings',
          value: 'direct',
        },
        {
          label: 'Fund of funds portfolio',
          value: 'funds',
        },
      ]}
      defaultValue={uploadType}
      onClick={(value) => {
        setUploadType(value)
        setPortfolioFile(undefined)
        setErrorMessage(null)
      }}
      fullWidth
    />
    {uploadType === 'direct' ? <DirectText /> : <FundsText />}
    <div>
      <LabelText>Upload file</LabelText>
      <DragUpload
        accept={uploadType === 'direct' ? '.xlsx, .csv' : '.xlsx'}
        maxCount={1}
        // NOTE: to prevent default POST request being sent on file change.
        customRequest={() => {}}
        onChange={({ file }) => setPortfolioFile(file.originFileObj)}
        data-testid="file-input"
        // Prevent antd default file list from being shown
        fileList={[]}
      >
        {portfolioFile ? (
          <UploadedFileContainer>
            <Icon icon="Excel" alt="Excel icon" viewbox="0 0 33 32" width={33} height={32} />
            <div>{portfolioFile.name}</div>
            <Icon
              icon="Delete"
              alt="Remove file"
              onClick={(event) => {
                // Prevent overlapping click events of DragUpload and this from both triggering
                event.stopPropagation()
                setPortfolioFile(undefined)
              }}
            />
          </UploadedFileContainer>
        ) : (
          <UploadContainer>
            <Icon
              icon="Upload"
              alt="Upload file"
              viewbox="0 0 40 39"
              width={40}
              height={39}
              color="architecture5"
            />
            <span>
              Drag & drop files or <LinkText>select a file</LinkText>
            </span>
            <span>Supported format: .xlsx{uploadType === 'direct' && ' and .csv'}</span>
          </UploadContainer>
        )}
      </DragUpload>
    </div>
    <BottomContainer>
      <span>
        Contact
        <a href="mailto:support@netpurpose.com" target="_blank">
          <LinkText> support@netpurpose.com </LinkText>
        </a>
        for support.
      </span>
      <Button
        type="primary"
        onClick={() => setStep(2)}
        disabled={portfolioFile === undefined || isPending}
        loading={isPending}
      >
        Next
      </Button>
    </BottomContainer>
  </ModalBodyContainer>
)

export const CreatePortfolioContent = () => {
  const { createPortfolio } = useTasks()
  const { closeModal } = useModalContext()
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [uploadType, setUploadType] = useState<'direct' | 'funds'>('direct')

  const { uploadPortfolio, isPending } = useUploadPortfolio({
    isFoFUpload: uploadType === 'funds',
    onSuccess: (token, portfolio) => {
      createPortfolio(token, portfolio)
      closeModal('createPortfolio')
    },
    onError: (err) => setErrorMessage(err),
  })
  const [step, setStep] = useState<1 | 2>(1)
  const [portfolioFile, setPortfolioFile] = useState<File>()

  const handleUpload = (formValues: {
    name: string
    type: PortfolioType
    totalValue: number
    valuationDate: Date
  }) => {
    if (!portfolioFile) {
      return
    }
    uploadPortfolio({
      ...formValues,
      currency: 'USD',
      file: portfolioFile,
    })
  }

  return (
    <>
      <ModalHeaderContainer>
        <ModalText>{step === 1 ? 'Select portfolio type' : 'Add portfolio details'}</ModalText>
        <div>
          {step === 1 ? (
            <StepCounter $active={true}>1</StepCounter>
          ) : (
            <Icon icon="TickInCircle" alt="Completed" height={20} width={20} />
          )}
          <ModalText>Upload holdings</ModalText>
          <Separator>―</Separator>
          <StepCounter $active={step === 2}>2</StepCounter>
          <ModalText>Portfolio details</ModalText>
        </div>
      </ModalHeaderContainer>
      <UploadHoldings
        step={step}
        setStep={setStep}
        portfolioFile={portfolioFile}
        setPortfolioFile={setPortfolioFile}
        setErrorMessage={setErrorMessage}
        isPending={isPending}
        uploadType={uploadType}
        setUploadType={setUploadType}
      />
      <CreatePortfolioForm
        handleUpload={handleUpload}
        setStep={setStep}
        fileName={portfolioFile?.name}
        visible={step === 2}
        isPending={isPending}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
      />
    </>
  )
}
