import { Upload } from 'antd'
import { UploadProps } from 'antd/es/upload/Upload'
import { Field, FieldProps } from 'formik'
import { FC, ReactNode } from 'react'
import styled from 'styled-components'

const StyledUpload = styled(Upload)`
  .ant-upload-list-item {
    height: 100%;
  }
  .ant-upload-list-item-name {
    white-space: wrap;
  }
`

type Props = { name: string; children: ReactNode } & UploadProps

const FileUpload: FC<Props> = ({ name, children, ...rest }: Props) => {
  return (
    <Field name={name} id={name}>
      {({ field: { value }, form: { setFieldValue } }: FieldProps) => (
        <StyledUpload
          {...(value ? { fileList: [value] } : {})}
          beforeUpload={(file) => {
            setFieldValue(name, file)
            // https://ant.design/components/upload/#components-upload-demo-upload-manually
            // return false to stop file attempting to upload somewhere immediately
            return false
          }}
          {...rest}
        >
          {children}
        </StyledUpload>
      )}
    </Field>
  )
}

export default FileUpload
