import { CheckboxChangeEvent } from 'antd/es/checkbox'
import { FC } from 'react'
import { Portfolio } from '@netpurpose/api'
import { CheckboxWOutFormik, NextLink as Link, NavigationTable, Table } from '@netpurpose/np-ui'
import { FilterConfig, PaginationConfig } from '@netpurpose/types'
import { useAnalytics } from '#context/analytics'
import { PortfolioCreationTask } from '#context/tasks'
import { config } from '#services/config'
import { AnalyticsEventName } from '#types/analytics'
import { EmptyPortfolioTable } from './EmptyPortfolioTable'
import {
  formatPortfolio,
  formatPortfolioCreationTask,
  getColumns,
  getPortfolioIds,
  PortfolioForDisplay,
} from './utils'

const PortfolioLink: FC<{ record: PortfolioForDisplay; children: React.ReactNode }> = ({
  record: portfolio,
  children,
}) => <Link href={config.dynamicRoutes.portfolioDetail(portfolio.id)}>{children}</Link>

type Props = {
  portfolios?: Portfolio[]
  pendingPortfolios?: PortfolioCreationTask[]
  isLoading: boolean
  selectedPortfolioIds?: Portfolio['id'][]
  setSelectedPortfolioIds?: (keys: Portfolio['id'][]) => void
  isInteractive?: boolean
  filterConfig?: FilterConfig<Portfolio>
  paginationConfig?: PaginationConfig
}

export const PortfolioTable: FC<Props> = ({
  portfolios,
  pendingPortfolios = [],
  isLoading,
  selectedPortfolioIds,
  setSelectedPortfolioIds,
  isInteractive = true,
  filterConfig,
  paginationConfig,
}) => {
  const displayData = [
    ...pendingPortfolios.map(formatPortfolioCreationTask),
    ...(portfolios || []).map(formatPortfolio),
  ]
  const analytics = useAnalytics()

  if (!isInteractive) {
    return (
      <Table
        rowKey={(row) => row.id}
        dataSource={displayData}
        columns={getColumns(paginationConfig, filterConfig)}
        loading={isLoading}
        pagination={config.defaultTablePagination}
        locale={{ emptyText: EmptyPortfolioTable }}
      />
    )
  }

  const rowSelectionConfig =
    selectedPortfolioIds && setSelectedPortfolioIds
      ? {
          hideSelectAll: true,
          selectedRowKeys: selectedPortfolioIds,
          getCheckboxProps: (row: PortfolioForDisplay) => ({
            disabled: row.resultStatus === 'pending',
            name: `${row.id}`,
            role: 'checkbox',
          }),
          // NOTE: the onChange handler is defined within the custom render
          // component rather than as a property on the selectionConfig because
          // in order to customise the checkbox, we need renderCell, and onChange
          // property on selectionConfig does not get triggered on change of
          // the custom checkbox component
          // NOTE: checked always seems to equal false hence is unused here
          renderCell: (_checked: boolean, record: PortfolioForDisplay) => (
            <CheckboxWOutFormik
              checked={selectedPortfolioIds.indexOf(record.id) !== -1}
              onChange={({ target: { checked } }: CheckboxChangeEvent) => {
                setSelectedPortfolioIds(
                  getPortfolioIds({
                    newSelectedRowId: record.id,
                    selectedPortfolioIds,
                    isBeingChecked: checked,
                  }),
                )
              }}
              aria-label={record.name}
            />
          ),
        }
      : undefined
  const paginationProps = paginationConfig
    ? {
        pagination: paginationConfig.tablePaginationConfig,
        onChange: paginationConfig.handleTableChange,
      }
    : { pagination: config.defaultTablePagination }

  const logTooltipHoverEvent = (tooltipName: string) =>
    analytics?.logEvent(AnalyticsEventName.Hover, {
      // No tooltips in company data table, so has to be portfolio data
      // if this function is called.
      table_name: 'Portfolio data',
      hover_item_name: `Tooltip - ${tooltipName}`,
    })

  return (
    <NavigationTable
      {...(rowSelectionConfig ? { rowSelection: rowSelectionConfig } : {})}
      rowKey={(row) => row.id}
      rowClassName={(row) => (row.resultStatus === 'complete' ? '' : 'disabledRow')}
      dataSource={displayData}
      columns={getColumns(paginationConfig, filterConfig, logTooltipHoverEvent)}
      loading={isLoading}
      renderLink={PortfolioLink}
      linkColumnDataIndex="name"
      locale={{ emptyText: EmptyPortfolioTable }}
      {...paginationProps}
      data-testid="portfolios-table"
    />
  )
}
