import { FC } from 'react'
import { PortfolioType } from '@netpurpose/api'
import { Tooltip } from '../Tooltip'
import { Badge } from './PortfolioBadge.style'

const content: Record<PortfolioType, { initials: string; title: string }> = {
  benchmark: { initials: 'B', title: 'Benchmark' },
  private: { initials: 'P', title: 'Portfolio' },
  watchlist: { initials: 'W', title: 'Watchlist' },
  'fund of funds': { initials: 'P', title: 'Fund of funds' },
  fund: { initials: 'F', title: 'Fund' },
}

export const PortfolioBadge: FC<{ type: PortfolioType; withoutTooltip?: boolean }> = ({
  type,
  withoutTooltip = false,
}) => {
  const initials = content[type]?.initials
  const title = content[type]?.title

  if (!initials || !title) {
    return null
  }
  if (withoutTooltip) {
    return (
      <Badge aria-label={title} type={type} className="follow-table-row-highlight-inverted">
        {initials}
      </Badge>
    )
  }
  return (
    <Tooltip placement="right" title={title}>
      <Badge aria-label={title} type={type} className="follow-table-row-highlight-inverted">
        {initials}
      </Badge>
    </Tooltip>
  )
}
