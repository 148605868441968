import { FC, useState } from 'react'
import { useUploadPortfolio } from '@netpurpose/api'
import { useModalContext, useUnleashFlag } from '@netpurpose/core'
import { LinkText, Modal, Text } from '@netpurpose/np-ui'
import { ModalActionTrigger } from '#components/portfolio/portfolioTypes'
import { UploadHelperContent } from '#components/portfolio/UploadHelperAction'
import { useAnalytics } from '#context/analytics'
import { useTasks } from '#context/tasks'
import { config } from '#services/config'
import { AnalyticsButtonName, AnalyticsEventName } from '#types/analytics'
import { CreatePortfolioContent } from './CreatePortfolioContent'
import CreatePortfolioFormOld from './CreatePortfolioFormOld'

export const CreatePortfolioContentOld = () => {
  const { createPortfolio } = useTasks()
  const { closeModal: closeActualModal } = useModalContext()
  const closeModal = () => closeActualModal('createPortfolioOld')
  const [showUploadHelper, setShowUploadHelper] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const { uploadPortfolio, isPending } = useUploadPortfolio({
    xNpFeature: ['ensure_unique_portfolio_name'],
    onSuccess: (token, portfolio) => {
      createPortfolio(token, portfolio)
      closeModal()
    },
    onError: (err) => setErrorMessage(err),
  })

  const analytics = useAnalytics()

  const onDownloadTemplate = () =>
    analytics?.logEvent(AnalyticsEventName.ButtonClick, {
      button_name: AnalyticsButtonName.DownloadPortfolioTemplate,
      location: 'create_portfolio_form',
    })

  if (showUploadHelper) {
    return <UploadHelperContent />
  }

  return (
    <>
      <Modal.ContentContainer>
        <Modal.HeaderText>Create your portfolio.</Modal.HeaderText>
        <Text>
          Upload your set of holdings in CSV. Make sure you&apos;ve got ISIN codes. If you
          don&apos;t want to share your fund size, just put $1M here. Not sure how to upload a
          portfolio? <LinkText onClick={() => setShowUploadHelper(true)}>Learn more</LinkText>
        </Text>
      </Modal.ContentContainer>
      <CreatePortfolioFormOld
        onSubmit={(formValues) => {
          setErrorMessage(null)
          uploadPortfolio(formValues)
        }}
        onDownloadTemplate={onDownloadTemplate}
        errorMessage={errorMessage}
        onCancel={closeModal}
        isSubmitting={isPending}
      />
    </>
  )
}

export const CreatePortfolioModalOld: FC = () => {
  const { isModalOpen, closeModal } = useModalContext()
  return (
    <Modal
      open={isModalOpen.createPortfolioOld}
      title="Create portfolio"
      onClose={() => closeModal('createPortfolioOld')}
    >
      <CreatePortfolioContentOld />
    </Modal>
  )
}

export const CreatePortfolioModal: FC = () => {
  const { isModalOpen, closeModal } = useModalContext()
  return (
    <Modal
      open={isModalOpen.createPortfolio}
      title="Create portfolio"
      onClose={() => closeModal('createPortfolio')}
      size="large"
    >
      <CreatePortfolioContent />
    </Modal>
  )
}

type Props = {
  renderAction: FC<ModalActionTrigger>
}

export const CreatePortfolioAction: FC<Props> = ({ renderAction: Action }) => {
  const { openModal } = useModalContext()
  const fundOfFundsEnabled = useUnleashFlag(config.featureToggles.fundOfFunds)

  return (
    <Action
      onClick={() => openModal(fundOfFundsEnabled ? 'createPortfolio' : 'createPortfolioOld')}
    />
  )
}
